@font-face {
  font-family: 'ENGLISH157';
  src: url(./ENGLISH157.ttf);
}
*{
  box-sizing: border-box;
}

body {
  background-color: #85FFBD;
  background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 12px;
}
h1 {
  text-align: center;
  font-family: 'ENGLISH157';
  font-size: 40px;
  line-height: 100%;
}
