.App {
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 40px;
}
.App__paragraph{
  text-align: center;
  background-color: aqua;
  width: max-content;
  margin: 0 auto 12px;
  padding: 4px;
  font-size: 14px;
  font-weight: bold;
  animation: bcgColor 10s infinite;
  width: 179px;
}
.App__video-carousel{
  overflow: auto;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  padding: 0 12px;
}
@media (min-width: 768px){
  .App__video-carousel{
    margin: 0 auto;
    padding: 0;
    justify-content: center;
  }
 
}
.show-carousel{
  overflow: auto;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  padding: 0 12px;
}
.show{
  margin-right: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}
.show__img{
  width: 200px;
  height: 100%;
}
.show--last{
  padding-right: 12px;
}
.App__video-slide{
  margin-right: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.App__video-slide--last{
  padding-right: 12px;
}
.bar-carousel{
  overflow: auto;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  padding: 0 12px;
}
.bar-carousel__slide {
  position: relative;
  margin-right: 8px;
}
.bar-carousel__slide--last {
  padding-right: 12px;
}
.bar-carousel__img{
  width: 270px;
  height: auto;
  object-fit: cover;
  min-height: 180px;
  border-radius: 8px;
}
.bar-carousel__text{
  font-size: 24px;
  color: black;
  font-weight: bold;
  text-align: left;
}
.App__ul{
  padding: 0 0 0 16px;
  margin: 0;
  list-style: disc;
}
.App__entrance-rules{
  text-align: center;
}
.App__entrance-title{
  color: red;
}

@keyframes bcgColor {
  0% {background-color: red;}
  10% {background-color:  green;}
  20% {background-color: salmon;}
  30% {background-color: yellow;}
  40% {background-color: blue;}
  50% {background-color: black;}
  60% {background-color: wheat;}
  70% {background-color: thistle;}
  80% {background-color: tomato;}
  90% {background-color: lightpink;}
  100% {background-color: orange;}
}